<template>
    <input v-model="modelValue" class="tb-input" @change="setValue"/>
</template>
<script>

export default {
  props:['modelValue'],
  emits:['update:modelValue'],
  data() {
    return {
      //inputValue:""
    };
  },
  methods:{
    setValue($event){
      this.$emit('update:modelValue',$event.target.value)
    }
  },
  mounted() {
    //this.inputValue = this.modelValue
  },
  created() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tb-input{
  border: 0;
  box-shadow: none !important;
  background-color: rgba(238, 246, 253, 0);
  width: auto !important;
}
</style>
