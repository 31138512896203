<template>
    <el-dialog v-model="show" :title="title" destroy-on-close>
      <component :is="form" v-model="formData" @setTitle="setTitle"></component>
    </el-dialog>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  props:['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      form:"",
      title:"",
      formData:'',
      show:false
    };
  },
  methods: {
    setTitle(e){
      this.title = e;
    }
  },
  watch:{
    modelValue(v){
      this.formData = v.data;
      this.show = true;
      console.log("sad",v)
    },
    //url:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
  },
  mounted() {
    let v = this.modelValue;

    this.form = defineAsyncComponent({
      // 加载函数
      loader: () => import ('../components/'+v.type),

      // 加载异步组件时使用的组件
      //loadingComponent: LoadingComponent,
      // 展示加载组件前的延迟时间，默认为 200ms
      delay: 200,

      // 加载失败后展示的组件
      //errorComponent: ErrorComponent,
      // 如果提供了一个 timeout 时间限制，并超时了
      // 也会显示这里配置的报错组件，默认值是：Infinity
      timeout: 3000
    });
  }
}
</script>
<style scoped>

</style>
