<template>
  <div class="main" v-if="user">
    <el-container>
      <el-header>

          <el-link href="/" class="logo">
            <el-image style="width: 120px; height: 60px" :src="logo_url" fit="contain" />
          </el-link>

        <el-menu
            :default-active="activeIndex"
            class="header-nav"
            mode="horizontal"
            :ellipsis="false"
        >
          <el-menu-item index="10">Processing Center</el-menu-item>
          <el-sub-menu index="20">
            <template #title>Workspace</template>
            <el-menu-item index="2-1">item one</el-menu-item>
            <el-menu-item index="2-2">item two</el-menu-item>
            <el-menu-item index="2-3">item three</el-menu-item>
            <el-sub-menu index="2-4">
              <template #title>item four</template>
              <el-menu-item index="2-4-1">item one</el-menu-item>
              <el-menu-item index="2-4-2">item two</el-menu-item>
              <el-menu-item index="2-4-3">item three</el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
          <el-menu-item index="3" disabled>Info</el-menu-item>
          <el-menu-item index="4">Orders</el-menu-item>
          <div class="flex-grow" />
            <el-menu-item index="11">
              <el-link :underline="false" href="/admin">消息中心</el-link>
            </el-menu-item>
            <el-menu-item index="ad" @click="dialog('User',user)">
              <template #title>
                <el-avatar :size="35" :src="user.avatar" /><span style="margin-left: 5px">{{user.username}}</span>
              </template>
            </el-menu-item>
            <el-menu-item index="login" @click="loginOut">
              <template #title>
                <div><el-icon><SwitchButton /></el-icon></div>
              </template>
            </el-menu-item>
        </el-menu>
      </el-header>
      <el-container class="body">
        <el-aside width="220px" :style="{height:height+'px'}">
            <el-scrollbar>
              <el-menu :default-openeds="openeds" router v-loading="loading">
                <div v-for="(item,key) in menu" :key="key">
                  <el-sub-menu v-if="item.children" :index="item.id.toString()" :route="item.path">
                    <template #title>
                      <el-icon><component :is="item.icon"></component> </el-icon>{{ item.title }}
                    </template>
                    <el-menu-item-group title="Group">
                      <el-menu-item v-for="(subItem,subKey) in item.children" :key="subKey" :index="subItem.id.toString()" :route="{name:subItem.path}">
                        <template #title>
                          <el-icon><component :is="subItem.icon"></component> </el-icon>{{ subItem.title }}
                        </template>
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-sub-menu>
                  <el-menu-item v-else :index="item.id.toString()" :route="item.path">
                    <template #title>
                      <el-icon><component :is="item.icon"></component> </el-icon>{{ item.title }}
                    </template>
                  </el-menu-item>
                </div>
                <div style="height: 50px"></div>
              </el-menu>
            </el-scrollbar>
        </el-aside>
        <el-container class="center">
            <el-main>
              <router-view :height="height"></router-view>
            </el-main>
          <!--el-footer>
            <p>前端框架：Element Plus</p>
            <p>后端框架：Laravel 5.6 +  Php 7.4</p>
          </el-footer-->
        </el-container>
      </el-container>
    </el-container>
    <m-dialog :load="load"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    let user = sessionStorage.getItem("user");
        user = JSON.parse(user);
    let system = sessionStorage.getItem("system");
        system = JSON.parse(system);
    return {
      load:{},
      openeds:['75'],
      activeIndex:"",
      user,
      logo_url:system.logo,
      title: sessionStorage.getItem("title"),
      height: 100
    };
  },
  methods: {
    ...mapActions('Login',[
      'getMenu'
    ]),
    dialog(form,data){
      let show = true;
      this.load = {form,data,show}
    },
    setHeight(){
      let windowH = document.documentElement.clientHeight;
      this.height = windowH - 60;
    },
    loginOut(){
      this.$confirm('您确定要退出登录？',
          '警告',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(()=>{
            sessionStorage.clear();
            this.$router.push("/login")
          })
    }
  },
  computed: mapState('Login',{
    menu: state => state.menu,
    loading:state => state.loading,
  }),
  mounted() {
    this.getMenu();
    this.setHeight();
    window.onresize = ()=>{
      this.setHeight();
    };
  },
  beforeRouteEnter(to) {
    const user = sessionStorage.getItem("user");
    if (!user){
      window.location.href = "login";
    }else {
      sessionStorage.setItem("title",to.meta.title);
    }
    // 在渲染该组件的对应路由被验证前调用
    // 不能获取组件实例 `this` ！
    // 因为当守卫执行时，组件实例还没被创建！
  },
  beforeRouteUpdate(to) {
    this.title = to.meta.title
    // 在当前路由改变，但是该组件被复用时调用
    // 举例来说，对于一个带有动态参数的路径 `/users/:id`，在 `/users/1` 和 `/users/2` 之间跳转的时候，
    // 由于会渲染同样的 `UserDetails` 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
    // 因为在这种情况发生的时候，组件已经挂载好了，导航守卫可以访问组件实例 `this`
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-aside{
  color: var(--el-text-color-primary);
}
.el-aside,.el-header{
  box-shadow: var(--el-box-shadow);
}
.el-header{
  display: flex;
  align-items: center;
  padding: 0;
}
.header-nav{
  flex: 1;
}
.flex-grow{
  flex-grow: 1;
}
.logo{
  width: 220px;
  text-align: center;
}
.el-footer p{
  font-size: 14px;
}
.el-page-header{
  margin-bottom: 30px;
}
.el-main{
  display: flex;
  flex-direction:column;
  padding: 10px 10px 0 10px;
}
.center{
  flex: 1;
}
</style>
