import api from './../plugins/httpRequest'

const apiDetail = (params)=>{
    return api.get("/api/auth-users/"+params.id)
}

const apiList = (params)=>{
    return api.get("/api/auth-users",{params})
}

const apiSave = (params)=>{
    if (params.id){
        return api.put('/api/auth-users/'+ params.id, params)
    }else {
        return api.post('/api/auth-users', params)
    }
}

export default {
    namespaced: true,
    state: {
        list:[],
        detail:{},
        loading:false,
        detailLoading:false
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        DETAIL(state, data) {
            state.detail = data;
            state.loading = false;
            state.detailLoading = false;
        },
        LIST(state, result) {
            state.loading = false
            state.list = result.data;
        }
    },
    actions: {
        list({commit,state},params) {
            state.loading = true;
            apiList(params).then(function(res) {
                commit('LIST', res.data);
            },function() {
                state.loading = false;
            });
        },

        detail({commit,state},params) {
            state.detailLoading = true;
            if (params.id){
                apiDetail(params).then(function(res) {
                    commit('DETAIL', res.data);
                });
            }else {
                commit('DETAIL', {});
            }
        },

        save({commit,state},params) {
            state.loading = true;
            apiSave(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.loading = false;
            });
        }
    }
}
