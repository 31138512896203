<template>
  <div style="display: block;width: 100%">
    <el-input v-model="value" placeholder="可输入图片地址">
      <template #append>
        <el-upload
            :action="action"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload">
          <div style="display: flex;align-items: center">
            <text>上传图片</text><el-icon class="el-icon--right"><Upload /></el-icon>
          </div>
        </el-upload>
      </template>
    </el-input>
    <el-image
        v-if="imageUrl"
        :src="imageUrl"
        :onload="loadSuccess"
        :onerror="loadFail"
        style="width: 80px;height: 80px;display: flex;margin-top: 5px"
        :preview-src-list="[imageUrl]"
        fit="contain">
    </el-image>
    <div v-else style="width: 80px;height: 80px;display: flex;margin-top: 5px;justify-content: center;align-items: center;background-color: #f5f7fa">
      <el-icon :size="25" color="#c0c4cc"><PictureFilled /></el-icon>
    </div>
  </div>
</template>
<script>
export default {
  props:['modelValue'],
  emits: ['update:modelValue'],
  data() {
    let user = sessionStorage.getItem('user');
        user = JSON.parse(user);
    return {
      action:'/api/upload?api_token=' + user.api_token,
      imageUrl:null,
        value:""
    };
  },
  methods: {
    loadFail(){

    },
    loadSuccess(){

    },
    handleSuccess(response,file) {
      this.value = response;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        return false;
      }
      const isImage = file.type==="image/jpeg" || file.type==="image/png" || file.type==="image/gif";
      if (!isImage){
        this.$message.error('上传图片格式错误，请上传图片!');
        return false;
      }
      return true;
    }
  },
  watch: {
      value(v){
          if (v !== this.modelValue){
              this.$emit('update:modelValue',v)
          }
      },
      modelValue(v){
          if (v){
              this.imageUrl = v;
              this.value = v;
          }else {
              this.imageUrl = null;
              this.value = "";
          }
      }
  },
  mounted() {

  }
}
</script>
<style scoped>

</style>
