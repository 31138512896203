import api from './../plugins/httpRequest'

const uri = "/api/agents";

const apiSave = (params)=>{
    if (params.id){
        return api.put(uri + '/'+ params.id, params)
    }else {
        return api.post(uri, params)
    }
}

const apiGetUsers = (params)=>{
    return api.get("/api/agents-users", {params})
}

const apiGet = (params)=>{
    if (params.id){
        return api.get(uri +"/"+params.id)
    }else {
        return api.get(uri, {params})
    }
}

const apiGetOptions = (params)=>{
    return api.get('/api/option/agents', {params})
}

const apiBindUsers = (params)=>{
    return api.post(uri+'-users', params)
}

export default {
    namespaced: true,
    state: {
        list:[],
        detail:{
            title:'',
            commission: [],
            level: ''
        },
        agentOptions:[],
        loading:false,
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        LIST(state, data) {
            state.loading = false
            state.list = data.data;
        },

        DETAIL(state, data) {
            state.loading = false
            state.detail = data;
        },

        OPTION(state, data) {
            state.agentOptions = data;
        }
    },
    actions: {

        list({commit,state},params) {
            state.loading = true;
            params.limit = 20;
            apiGet(params).then(function(res) {
                commit('LIST', res.data);
            },function() {
                state.loading = false;
            });
        },

        detail({commit,state},params) {
            state.loading = true;
            apiGet(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.loading = false;
            });
        },

        save({commit,state},params) {
            state.loading = true;
            apiSave(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.loading = false;
            });
        },

        users({commit,state},params) {
            state.loading = true;
            params.limit = 20;
            apiGetUsers(params).then(function(res) {
                commit('LIST', res.data);
            },function() {
                state.loading = false;
            });
        },

        options({commit,state},params){
            apiGetOptions(params).then(function(res) {
                commit('OPTION', res.data);
            },function() {
                state.loading = false;
            });
        },

        bind({commit,state},params) {
            state.loading = true;
            apiBindUsers(params).then(function(res) {
                commit('LIST', res.data);
            },function() {
                state.loading = false;
            });
        },
    }
}
