<template>
  <el-input v-model="file_path" placeholder="可输入文件路径">
    <template #append>
      <el-upload
          :action="action"
          :show-file-list="false"
          :on-success="handleSuccess"
          :before-upload="beforeUpload">
        <div style="display: flex;align-items: center">
          <text>上传</text><el-icon class="el-icon--right"><Upload /></el-icon>
        </div>
      </el-upload>
    </template>
  </el-input>
</template>
<script>
export default {
  props:['modelValue'],
  emits: ['update:modelValue'],
  data() {
    let user = sessionStorage.getItem("user");
    user = JSON.parse(user);
    return {
      action:'/api/upload/file?api_token='+user.api_token,
      file_path : "",
    };
  },
  methods: {
    handleSuccess(e) {
        this.$emit('update:modelValue',e);
    },
    beforeUpload(e) {
      //console.log(e);
    }
  },
  watch: {
    file_path(e){
      this.$emit('update:modelValue',e);
    },
    modelValue(e){
      this.file_path = e;
    }
  },
  mounted() {

  }
}
</script>
<style scoped>

</style>
