<template>
  <div id="toolbar">
    <el-dialog v-model="show" :title="title" destroy-on-close>
      <component :is="form" :fromData="formData" @setTitle="setTitle"></component>
    </el-dialog>
  </div>
</template>
<script>
    import { defineAsyncComponent} from 'vue'

export default {
  props:['load'],

  data() {
    return {
      show:false,
      formData:{},
      title:""
    };
  },
  methods: {
    setTitle(e){
      this.title = e;
    }
  },
  watch:{
    load(v){
      console.log(v);
      this.form = defineAsyncComponent({
        // 加载函数
        loader: () => import ('../forms/'+v.form),

        // 加载异步组件时使用的组件
        //loadingComponent: LoadingComponent,
        // 展示加载组件前的延迟时间，默认为 200ms
        delay: 200,

        // 加载失败后展示的组件
        //errorComponent: ErrorComponent,
        // 如果提供了一个 timeout 时间限制，并超时了
        // 也会显示这里配置的报错组件，默认值是：Infinity
        timeout: 3000
      });
      this.show = v.show;
      this.formData = v.data;
    }
  },
  mounted() {

  }
}
</script>
<style scoped>
#toolbar >>> .el-dialog{
  min-width: 600px;
}
</style>
