<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
      data() {
          return {};
      },
      methods: {
          ...mapActions('Config',[
              'getSystem'
          ])
      },
      computed: mapState('Config',{
          system: state => state.system
      }),
      mounted: function () {

      },
      watch:{
          system(v){
            if (v.body){
                sessionStorage.setItem('system', JSON.stringify(v.body));
            }
        }
      },
      created() {
          this.getSystem();
      }
  }
</script>

<style>
  body{
    padding: 0;
    margin: 0;
  }
  .shadow{
      box-shadow: var(--el-box-shadow-lighter);
      margin: 10px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-header .bold{
    font-weight: bold;
    padding-right: 2em;
  }
  th{
    background-color: #0D2449;
  }
  .between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
