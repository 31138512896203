import api from './../plugins/httpRequest'

const apiLogin = (params)=>{
    return api.post("api/login",params)
}

const getMenu = (params)=>{
    return api.get("api/menus",{params})
}

const updateItem = (params)=>{
    return params.map(function (item){
        item.icon = item.icon.split("-").pop();
        if (item.children){
            item.children = updateItem(item.children);
        }
        return item;
    })
}

export default {
    namespaced: true,
    state: {
        loading:false
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        LOGIN(state, user) {
            sessionStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("menus", JSON.stringify(user.menus));
            state.loading = false;
        },
        LIST(state, data) {
            state.loading = false
            state.menu = updateItem(data);
        }
    },
    actions: {
        login({commit,state},params) {
            state.loading = true;
            apiLogin(params).then(function(res) {
                commit('LOGIN', res.data);
            },function() {
                state.loading = false;
            });
        },
        getMenu({commit,state},params) {
            state.loading = true;
            getMenu(params).then(function(res) {
                commit('LIST', res.data);
            },function() {
                state.loading = false;
            });
        },
    }
}
