import { createApp } from 'vue'
import App from './App.vue'

import Ele from 'element-plus'
import 'element-plus/dist/index.css'

import Router from "./plugins/router"
import Store from "./plugins/store"
import Areas from './plugins/area'

import Toolbar from "./pages/Toolbar"
import Dialog from './pages/Dialog'
import Price from './components/Price'
import Input from './components/Input'
import Index from './components/Index'
import File from './components/upload/File'
import Image from './components/upload/Image'
import Images from './components/upload/Images'
import Tags from './components/Tags'
import Location from './components/Location'

const app = createApp(App);
        app.config.globalProperties.$areas = Areas;
        app.use(Ele);
        app.use(Router);
        app.use(Store);
        app.mount("#app");
        app.component('el-toolbar',Toolbar);
        app.component('m-dialog',Dialog);
        app.component('m-price',Price);
        app.component('m-input',Input);
        app.component('m-component',Index);
        app.component('m-file',File);
        app.component('m-image',Image);
        app.component('m-images',Images);
        app.component('m-tags',Tags);
        app.component('location',Location);

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
}
