import http from 'axios'
import { ElNotification } from 'element-plus'
// http request 拦截器
http.interceptors.request.use(
    config => {
        /*
        if (store.state.token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = `token ${store.state.token}`;
        }
        console.log(config);
        */
        let user = sessionStorage.getItem("user");
        if (user)
        {
            const {api_token} = JSON.parse(user);
           // config.url += "?api_token=" + api_token;
            config.headers.Authorization = `Bearer ${api_token}`;
        }
        return config;
    },
    error => {
        console.log(error.response)
        return Promise.reject(error.response);
    });

// http response 拦截器

http.interceptors.response.use(
    response => {
        let message = null;
        switch (response.config.method.toUpperCase()){
            case "PUT":
                message = "数据更新完成！";
                break;
            case "POST":
                message = "数据保存完成！";
                break;
            case "DELETE":
                message = "数据删除完成！";
                break;
            case "GET":
                //Vue.prototype.search = response.data.search;
                break;
        }
        if(message){
            ElNotification({
                title: 'Success',
                message: message,
                type: 'success',
            });
        }
        return response;
    },
    error => {
        let message = null;
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    message = error.response.data.msg;
                    break;
                case 401:
                    // 返回 401 清除token信息并跳转到登录页面
                    sessionStorage.removeItem("token");
                    window.location.href = "login";
                    break;
                case 404:
                    message = "接口路由不存在！";
                    break;
                case 405:
                    message = "接口请求方式【"+error.response.config.method+"】不存在！";
                    break;
                case 422:
                    message = "数据验证失败！";
                    break;
                case 500:
                    message = "服务器程序错误！";
                    break;
            }
        }
        if (message){
            ElNotification({
                title: 'Error',
                message: message,
                type: 'error',
            });
        }
        return Promise.reject(error.response)   // 返回接口返回的错误信息
    });

export default http;
