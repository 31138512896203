<template>
    <el-form :model="form"
             label-width="5em"
             class="form"
             :size="formSize"
             :rules="rules"
             ref="ruleFormRef">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" autocomplete="off" />
      </el-form-item>
      <el-form-item label="图片" prop="url">
        <el-input v-model="form.url" placeholder="输入图片地址">
          <template #append><el-icon><Upload/></el-icon></template>
        </el-input>
      </el-form-item>
      <el-form-item label="连接地址" >
        <el-input v-model="form.path" autocomplete="off" />
      </el-form-item>
      <div style="text-align: right">
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)" >提交</el-button>
      </div>
    </el-form>
</template>
<script>
export default {
  props:['data'],
  emits: ['onSetData','setTitle'],
  data() {
    return {
      formSize:"default",
      ruleFormRef : "ruleFormRef",
      rules: {
        title: [
            { required: true, message: '标题不能为空', trigger: 'blur' },
            { min: 3, max: 20, message: '请输入3到20个字符', trigger: 'blur' },
        ],
        url: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ]
      },form:{
        url: "",
        path: "",
        title: ""
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid,fields) => {
        if (valid) {
          this.$emit('onSetData',[this.form]);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {

  },
  mounted() {
      this.$emit('setTitle',"添加图文");
      if (this.data.length > 0){
        this.form = this.data[0];
      }else {
        this.form = {
          url: "",
          path: "",
          title: ""
        };
      }
  }
}
</script>
<style scoped>
  .form{
    padding-right: 5em;
  }
</style>
