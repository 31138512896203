<template>
  <el-tag
      v-for="tag in tags"
      :key="tag"
      class="mx-1"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)"
  >
    {{ tag }}
  </el-tag>
  <el-input
      v-if="visible"
      ref="InputRef"
      v-model="inputValue"
      class="ml-1 w-20"
      size="small"
      @keyup.enter="handleInputConfirm"
      @blur="handleInputConfirm"
  />
  <el-button v-else class="ml-1" size="small" @click="showInput">
    + 添加
  </el-button>
</template>
<script>

export default {
  props:['modelValue'],
  emits:['update:modelValue'],
  data() {
    return {
      tags:[],
      visible:false,
      inputValue:""
    };
  },
  methods:{
    handleClose(tag){
      this.tags.splice(this.tags.indexOf(tag), 1)
    },
    showInput(){
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['InputRef'].focus()
      })
    },
    handleInputConfirm(){
      let tag = this.inputValue;
      if (tag) {
        this.tags.push(tag)
      }
      this.visible = false;
      this.inputValue = ''
    }
  },
  watch: {
    modelValue(v){
      this.tags = v?v:[];
    },
    tags(v){
      this.$emit('update:modelValue',v)
    }
  },
  mounted() {
    this.tags = this.modelValue ? this.modelValue : []
  },
  created() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w-20{
  width: 60px;
}
.el-tag{
  margin: 3px 5px 3px 0;
}
.ml-1{
  margin: 3px 0;
}
</style>
