import api from './../plugins/httpRequest'

const uri = "/api/users";

const apiGet = (params)=>{
    let id = params ? params.id : null;
    if (id){
        return api.get(uri+"/"+id)
    }else {
        return api.get(uri,{params})
    }
}

const apiSet = (params)=>{
    let id = params ? params.id : null;
    if (id){
        return api.put(uri+"/"+id,params)
    }else {
        return api.post(uri,params)
    }
}

const apiOptions = (params)=>{
    return api.get("api/option/users",{params})
}

export default {
    namespaced: true,
    state: {
        list:[],
        detail:{},
        loading:true
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        LIST(state, result) {
            state.loading = false
            state.list = result.data;
        },
        DETAIL(state, result) {
            state.loading = false
            state.detail = result;
        }
    },
    actions: {
        get({commit,state},params) {
            state.loading = true;
            apiGet(params).then(function(res) {
                if (params && params.id){
                    commit('DETAIL', res.data);
                }else {
                    commit('LIST', res.data);
                }
            },function() {
                state.loading = false;
            });
        },
        set({commit,state},params) {
            state.loading = true;
            apiSet(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.loading = false;
            });
        },
        search({commit,state},params) {
            state.loading = true;
            apiOptions(params).then(function(res) {
                commit('LIST', res.data);
            },function() {
                state.loading = false;
            });
        }
    }
}
