<template>
  <div class="price">
      <text>{{value1}}</text>.<text class="mini">{{value2}}</text>
  </div>
</template>
<script>

export default {
  props:['price'],
  data() {
    return {
      value1:"0",
      value2:"00"
    };
  },
  watch: {
    price(val){
      if (val){
        let str = (val*1).toFixed(2).split(".");
        this.value1 = str[0];
        this.value2 = str[1];
      }
    }
  },
  created() {
    if (this.price){
      let str = (this.price*1).toFixed(2).split(".");
      this.value1 = str[0];
      this.value2 = str[1];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.price{
  display: inline;
  color: #FF8000FC;
  font-weight: bold;
}
.mini{
  font-size: 12px;
}
</style>
