import { createStore } from 'vuex'

import Login from './../stores/login';
import Menu from './../stores/menu';
import Admin from './../stores/admin';
import Shop from './../stores/shop';
import Agent from './../stores/agent';
import User from './../stores/user';
import Role from './../stores/role';
import Config from './../stores/config';
import Commission from './../stores/commission';
import CommissionApply from './../stores/commissionApply';
import Order from './../stores/order';
import Product from './../stores/product';
import Goods from './../stores/goods';
import GoodsType from './../stores/goodsType';
import Page from './../stores/page';
const store = createStore({
    modules:{
        Login,Menu,Admin,Shop,Agent,User,Role,Config,Commission,CommissionApply,Order,Product,Page,Goods,GoodsType
    }
});

export default store;