<template>
  <div id="toolbar">
    <div class="search">

        <el-tag
                class="shadow"
            v-for="tag in tags"
            :key="tag.key"
            closable
            :type="tag.type"
            size="large">
          {{ tag.name }}
        </el-tag>
        <el-button type="success" plain @click="update('user',1)" class="shadow">查询条件</el-button>
        <el-button type="primary" class="shadow">添加数据</el-button>

    </div>
    <div class="tools">

    </div>
    <component :is="shops"></component>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  props:['id'],
  data() {
    let checkUser = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空！'));
      }else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空！'));
      } else {
        callback();
      }
    };
    return {
      shops:"",
      formSize:"large",
      ruleFormRef : "ruleFormRef",
      tags: [
        { key: "name", name: '在线' },
        { key: "name", name: '密码不能为空' },
        { key: "name", name: '可用' },
        { key: "name", name: '2022-09-08' },
      ],
      rules: {
        username: [
          { validator: checkUser, trigger: 'blur' }
        ]
      },
      options:this.$areas
    };
  },
  methods: {
    ...mapActions('Shop',[
      'detail','save','options'
    ]),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save(this.shopForm);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(e){

    },
    beforeAvatarUpload(e){

    },
    searchUser(e){
      this.options({nickname:e});
    }
  },
  computed: mapState('Shop',{
    userOptions: state => state.userOptions,
    shopForm: state => state.detail,
    loading: state => state.loading
  }),
  mounted() {
    let id = this.id;
    this.detail({id});
  }
}
</script>
<style scoped>
.el-form{
  width: 500px;
}
.avatar-uploader{
  display: inherit;
}
.avatar-uploader .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}
</style>
