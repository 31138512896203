import api from './../plugins/httpRequest'

const uri = "/api/orders"

const apiGet = (params)=>{
    if (params && params.id){
        return api.get(uri +"/"+params.id)
    }else {
        return api.get(uri, {params})
    }
}

const apiSet = (params)=>{
    if (params.id){
        return api.put(uri+'/'+ params.id, params)
    }else {
        return api.post(uri, params)
    }
}

export default {
    namespaced: true,
    state: {
        list:[],
        detail:{},
        loading:false
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        DETAIL(state, data) {
            state.loading = false
            state.detail = data;
        },
        LIST(state, result) {
            state.loading = false
            state.list = result.data;
        }
    },
    actions: {
        get({commit,state},params) {
            state.loading = true;
            apiGet(params).then(function(res) {
                if (params && params.id){
                    commit('DETAIL', res.data);
                }else {
                    commit('LIST', res.data);
                }
            },function() {
                state.loading = false;
            });
        },
        set({commit,state},params) {
            state.loading = true;
            apiSet(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.loading = false;
            });
        }
    }
}
