import api from './../plugins/httpRequest'

const uri = "/api/shops";

const apiSave = (params)=>{
    if (params.id){
        return api.put(uri + '/'+ params.id, params)
    }else {
        return api.post(uri, params)
    }
}

const apiGet = (params)=>{
    if (params.id){
        return api.get(uri +"/"+params.id)
    }else {
        return api.get(uri, {params})
    }
}

const userOptions = (params)=>{
    return api.get('/api/option/users', {params})
}

export default {
    namespaced: true,
    state: {
        list:[],
        detail:{
            image:'',
            name: '',
            password: ''
        },
        userOptions:[
            {id:1,nickname:"v"},
            {id:2,nickname:"a"}
        ],
        loading:false,
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        LIST(state, data) {
            state.loading = false
            state.list = data.data;
        },

        DETAIL(state, data) {
            state.loading = false
            state.detailLoading = false
            state.detail = data;
        },
        UPDATE(state, data) {
            state.list = state.list.map((item)=>{
                return item.id === data.id ? data : item;
            })
            state.loading = false;
        },
        CREATE(state, data) {
            state.list.unshift(data);
            state.loading = false;
        },
        OPTION(state, data) {
            state.userOptions = data;
        }
    },
    actions: {
        options({commit},params){
            userOptions(params).then(function(res) {
                commit('OPTION', res.data);
            });
        },

        list({commit,state},params) {
            state.loading = true;
            params.limit = 20;
            apiGet(params).then(function(res) {
                commit('LIST', res.data);
            },function() {
                state.loading = false;
            });
        },

        get({commit,state},params) {
            if (params.id){
                state.detailLoading = true;
                apiGet(params).then(function(res) {
                    commit('DETAIL', res.data);
                    commit('OPTION', [res.data.user]);
                },function() {
                    state.detailLoading = false;
                });
            }else {
                commit('DETAIL', {});
                commit('OPTION', []);
            }
        },

        set({commit,state},params) {
            state.loading = true;
            const {id,region} = params;
            if (region){
                params.province = region[0]
                params.city = region[1]
                params.area = region[2]
            }
            apiSave(params).then(function(res) {
                commit(id ? 'UPDATE':'CREATE', res.data);
            },function() {
                state.loading = false;
            });
        }
    }
}
