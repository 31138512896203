<template>
    <div class="location">
      <el-row>
        <el-col :span="12">
          <el-input v-model="lon" placeholder="经度" @change="setValue"/>
        </el-col>
        <el-col :span="12">
          <el-input v-model="lat" placeholder="纬度" @change="setValue"/>
        </el-col>
      </el-row>
      <div id="mapContainer"></div>
    </div>
</template>
<script>
export default {
    props:['modelValue'],
    emits:['update:modelValue'],
  data() {
    return {
        lon:"116.307484",
        lat: "39.984120",
        map:null,
        markerLayer:null
    };
  },
  methods: {
    initMap(){
        if (this.map === null){
            let latLng = new TMap.LatLng(this.lat,this.lon);//设置地图中心点坐标
            let map = new TMap.Map(document.getElementById('mapContainer'), {
                center: latLng,
                zoom:17,   //设置地图缩放级别
            });
            //初始化marker图层
            let markerLayer = new TMap.MultiMarker({
                id: 'map-marker',
                map: map
            });
            markerLayer.add({
                id: 'map-marker',
                position: latLng
            });
            //绑定点击事件
            map.on("click",(evt)=>{
                markerLayer.updateGeometries([
                    {
                        "id": 'map-marker',
                        "position": evt.latLng
                    }
                ])

                this.lat = evt.latLng.getLat().toFixed(6);
                this.lon = evt.latLng.getLng().toFixed(6);
                this.$emit('update:modelValue',[this.lon,this.lat])
            })
            this.map = map;
        }
    },
    setValue() {
        this.map.setCenter(new TMap.LatLng(this.lat,this.lon));
        this.$emit('update:modelValue',[this.lon,this.lat])
    }
  },
  watch: {
      modelValue(v){
          this.lon = v[0],
          this.lat = v[1]
      },
  },
  created(){
      let key = 'PUYBZ-RO5RF-XFDJQ-NZN7A-CNW6T-H4BJZ',script = document.createElement("script");  //使用在腾讯位置服务申请的key
      script.type = "text/javascript";
      script.src = "https://map.qq.com/api/gljs?v=1.exp&key="+key;
      document.body.appendChild(script);
  },
  mounted() {
      setTimeout(()=>{
          this.initMap()
      },500)
  }
}
</script>
<style scoped>
  .location{
    width: 100%;
  }
</style>
