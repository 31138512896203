import api from './../plugins/httpRequest'

const uri = "/api/goods-types"

const apiGet = (params)=>{
    if (params && params.id){
        return api.get(uri +"/"+params.id)
    }else {
        return api.get(uri, {params})
    }
}

const apiSet = (params)=>{
    if (params && params.id){
        return api.put(uri+'/'+ params.id, params)
    }else {
        return api.post(uri, params)
    }
}

const apiDel = (id)=>{
    if (id){
        return api.delete(uri+"/"+id)
    }
}

export default {
    namespaced: true,
    state: {
        list:[],
        detail:{},
        loading:false,
        detailLoading:false
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        DETAIL(state, data) {
            state.detailLoading = false
            state.detail = data;
        },
        UPDATE(state, data) {
            state.list = state.list.map((item)=>{
                return item.id === data.id ? data : item;
            })
            state.loading = false;
        },
        CREATE(state, data) {
            state.list.unshift(data);
            state.loading = false;
        },
        DELETE(state, id) {
            state.list = state.list.filter(item =>item.id !== id);
            state.loading = false;
        },
        LIST(state, list) {
            state.loading = false
            state.list = list;
        }
    },
    actions: {
        get({commit,state},params={}) {
            const {id} = params;
            if (id){
                state.detailLoading = true;
            }else {
                state.loading = true;
            }
            apiGet(params).then(function(res) {
                commit(id ? 'DETAIL' : 'LIST', res.data);
            });
        },
        set({commit,state},params) {
            state.loading = true;
            const {id} = params;
            apiSet(params).then(function(res) {
                commit(id ? 'UPDATE' : 'CREATE', res.data);
            },function() {
                state.loading = false;
            });
        },
        del({commit,state},id) {
            state.loading = true;
            apiDel(id).then(function(res) {
                commit('DELETE', res.data);
            },function() {
                state.loading = false;
            });
        }
    }
}
